<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    initialoptions: {
      type: Object,
      default: null
    }
  },
  mounted () {
    this.renderChart(this.chartdata, this.initialoptions)
  },
  watch: {
    chartdata () {
      this.renderChart(this.chartdata.chartdata, this.chartdata.chartoptions)
    }
  }
}
</script>
