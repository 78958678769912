export default class MobileNav {
    constructor () {
      this.cookiesAlert = document.querySelector('.cookies-alert')
      this.mobileNav = document.querySelector('[data-is-mobile-nav]')
      this.satellite = document.querySelector('#satellite')
      this.init()
    }
  
    init () {
      let options = {
        root: null,
        rootMargin: '0px',
        threshold: 0
      }
      let observer = new IntersectionObserver(() => {
        return this.mobileNav.classList.toggle('d-flex')
      }, options);
      if (this.satellite) observer.observe(this.satellite)

      if (this.cookiesAlert) {
        const dismissCookies = this.cookiesAlert.querySelector('[data-dismiss]')
        dismissCookies.addEventListener('click', () => {
          this.displayMobileNav()
        })
      } else {
        this.displayMobileNav()
      }
    }
  
    displayMobileNav () {
      if (this.mobileNav) {
        return this.mobileNav.classList.toggle('d-flex')
      }
    }
  }
  