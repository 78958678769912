import { EmojiButton } from '@joeattardi/emoji-button';

export default class EmojiPicker {
  constructor () {
    this.picker = new EmojiButton()
    this.trigger = document.querySelector('#emoji-trigger');
    this.textarea = document.querySelector('#message_content')
    this.bindEvents()
  }

  bindEvents () {
    const self = this
    if (self.trigger) {
      self.trigger.addEventListener('click', () => self.picker.togglePicker(self.trigger));
      self.picker.on('emoji', selection => {
        const current_value = self.textarea.value
        self.textarea.value = current_value + selection.emoji
      })
    }
  }
}
