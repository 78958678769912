import FlashMessage from "src/shared/components/flash-messages"

document.addEventListener("DOMContentLoaded", () => {
  let message = document.head.querySelector('meta[name="flash-message"]')
  let type = document.head.querySelector('meta[name="flash-type"]')

  if (message && type) {
    new FlashMessage(message.content, type.content)
  }

  document.body.addEventListener('ajax:error', (event) => {
    if (event.detail[2] && event.detail[2].getResponseHeader('X-message')) {
      let content = JSON.parse(event.detail[2].getResponseHeader('X-message'))
      let message = decodeURIComponent(escape(content.message))
      new FlashMessage(message, content.type)
    }
  })

  document.body.addEventListener('ajax:success', (event) => {
    if (event.detail[2] && event.detail[2].getResponseHeader('X-message')) {
      let content = JSON.parse(event.detail[2].getResponseHeader('X-message'))
      let message = decodeURIComponent(escape(content.message))
      new FlashMessage(message, content.type)
    }
  })
})
