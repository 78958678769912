<template lang='pug'>
  modal(ref="modal" :id='modalId' whiteCross=true)
    template(slot="title")
      .flex.justify-center
          .font-bold.text-white.text-center Vous êtes ?
        
    .flex.justify-center(slot="body")
      .card.flex.flex-col.justify-center.items-center.p-4.card-hover.relative
        span.far.fa-user.text-secondary.my-2.text-5xl
        h3.text-primary.my-2.text-center Le futur colocastor
        span.my-2.text-center Je vivrai seul(e) dans ma chambre
        a.card-link.absolute.w-full.h-full(:href="url + '?warrantor=false'")
      .mx-4
      .card.flex.flex-col.justify-center.items-center.p-4.card-hover.relative
        span.far.fa-user-shield.text-primary.my-2.text-5xl
        h3.text-secondary.my-4.text-center Le garant du futur colocastor
        span.my-2.text-center Je loue la chambre pour un proche.
        a.card-link.absolute.w-full.h-full(:href="url + '?warrantor=true'")

</template>

<script>
  /* eslint no-undef: 0 */
   export default {
    components: {
      FormErrors: () => import('../../shared/components/FormErrors'),
      Modal: () => import('../../shared/components/Modal')
    },

    props: {
      modalId: [String, Number],
      roomId: String,
      backButtonLabel: {
        type: String,
        default: 'Annuler'
      }
    },

    data () {
      return {
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        errors: null,
        login: true,
        url: null
      }
    },

    methods: {
      show () { this.$refs.modal.show() },
      hide () { this.$refs.modal.hide() }
    },

    mounted() {
      this.url = `${window.location.origin}/chambres/${this.roomId}/locations/recapitulatif`
    }
  }
</script>
