<template lang="pug">
  form#setup-form(@submit.prevent.stop="handleSubmit")
    .form-row.inline
      .form-group.row
        label.col-md-3(for='accountholder-name')
          | Nom
        input.col-md-6.form-control#accountholder-name(name='accountholder-name' v-model="name" placeholder='John Doe' required='')
      .form-group.row
        label.col-md-3(for='email')
          | Adresse email
        input.col-md-6.form-control#email(name='email' type='email' v-model="email" placeholder='john.doe@example.com' required='')
      .form-group.row
        label.col-md-3
          | IBAN
        .col-md-6
          #iban-element

    .form-group.row
      .col-md-6.offset-md-3.p-0
        button#submit-button.btn.btn-secondary.btn-block(:data-secret='clientSecret')
          | Activer ma location

    #mandate-acceptance
      | En fournissant vos informations de paiement et en confirmant ce paiement, vous
      | autorisez (A) et Stripe, notre prestataire de services de paiement, à
      | envoyer des instructions à votre banque pour débiter votre compte et (B) votre banque à
      | débiter votre compte conformément à ces instructions. Dans le cadre de vos
      | Dans le cadre de vos droits, vous avez droit à un remboursement de la part de votre banque, conformément aux conditions de votre contrat avec votre banque.
      | conditions de votre contrat avec votre banque. Le remboursement doit être demandé
      | dans un délai de 8 semaines à compter de la date à laquelle votre compte a été débité.
      | Vos droits sont expliqués dans une déclaration que vous pouvez obtenir auprès de votre banque.
      | banque. Vous acceptez de recevoir des notifications pour les futurs débits jusqu'à 2 jours avant qu'ils ne se produisent.
      | avant qu'ils ne soient effectués.
    #error-message(role='alert')
</template>

<script>
export default {
  props: {
    clientSecret: String,
    roomSlug: String
  },
  data() {
    return {
      stripe: null,
      stripeKey: window.paymentConfig.stripeKey,
      name: null,
      email: null,
      elementOptions: {
        style: this.style,
        supportedCountries: ['SEPA'],
        placeholderCountry: 'FR'
      },
      style: {
        base: {
          color: '#32325d',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          },
          ':-webkit-autofill': {
            color: '#32325d',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
          ':-webkit-autofill': {
            color: '#fa755a',
          },
        },
      }
    }
  },
  methods: {
    handleSubmit() {
      this.stripe.confirmSepaDebitSetup(this.clientSecret, {
        payment_method: {
          sepa_debit: this.iban,
          billing_details: {
            name: this.name,
            email: this.email,
          },
        }
      }).then(() => {
        window.location.replace(`${window.location.origin}/chambres/${this.roomSlug}/locations/success`);
      })
    }
  },
  mounted() {
    this.stripe = Stripe(this.stripeKey)
    var elements = this.stripe.elements();
    this.iban = elements.create('iban', this.elementOptions);
    this.iban.mount('#iban-element')
  }
};
</script>