export default class UnfoldingEffect {
  constructor () {
    this.toggleElements = document.querySelectorAll('[data-unfold]')
    this.closeElement = document.querySelector('[data-fold]')

    this.bindEvents()
  }

  bindEvents () {
    this.toggleElements.forEach((toggleElement) => {
      const target = document.querySelector(toggleElement.dataset.unfold)
      if (target) {
        const close = target.querySelector('[data-fold]')

        toggleElement.addEventListener('click', () => {
          toggleElement.classList.add('active')
          document.querySelector('body').insertAdjacentHTML('beforeend', '<div class="modal-backdrop fade show"></div>')
          if (target.id === 'right_folder') {
            return target.classList.add('triggered-right')
          } else {
            return target.classList.add('triggered-left')
          }
        })
  
        close.addEventListener('click', () => {
          toggleElement.classList.remove('active')
          document.querySelector('.modal-backdrop').remove()
          if (target.id === 'right_folder') {
            target.classList.remove('triggered-right')
            return target.classList.add('triggered')
          } else {
            target.classList.remove('triggered-left')
            return target.classList.add('triggered')
          }
        })
      }
    })
  }
}
