<template lang='pug'>
  .flex.justify-between.flex-grow.items-center
    input(type="hidden" :value="formatted_address" name="formatted_address" v-if="address")
    input(type="hidden" :value="address.geometry.location.lng()" name="longitude" v-if="address")
    input(type="hidden" :value="address.geometry.location.lat()" name="latitude" v-if="address")

    gmap-autocomplete(
      ref="addressInput"
      id="map"
      class="form-control"
      placeholder="Je cherche une colocation à"
      @place_changed="getAddressData"
      @keypress.enter.prevent.stop=""
      :selectFirstOnEnter="true"
      :types="[]"
      :componentRestrictions="{ country: ['fr', 'be', 'it', 'es', 'lu', 'ch', 'ca', 'us'] }"
      @input="value = $event.target.value")

    .searchbar-localisation
      .spinner-wrapper.link-icon.link-icon--primary(v-if="geocoding")
        i.fa.fa-2x.fa-spinner.fa-spin
      a.link-icon.link-icon--primary(href="#" v-else @click.prevent="geolocate")
        i.fas.fa-location-arrow

</template>

<script>
  /* eslint no-undef: 0 */

  export default {
    props: {
      value: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        address: '',
        formatted_address: '',
        geocoding: false,
        autocompleteText: '',
        geolocation: {
          geocoder: null,
          loc: null,
          position: null
        }
      }
    },
    methods: {
      getAddressData: function (place) {
        this.address = place
        this.formatted_address = place.formatted_address
      },
      geolocate () {
        this.geocoding = true
        this.updateGeolocation((geolocation, position) => {
          this.updateCoordinates(geolocation)
          this.geocoding = false
        })
      },
      // https://github.com/xkjyeah/vue-google-maps/issues/181
      // https://github.com/olefirenko/vue-google-autocomplete/blob/master/src/VueGoogleAutocomplete.vue#L268
      updateGeolocation (callback = null) {
        if (navigator.geolocation) {
          let options = {}
          if (this.geolocationOptions) Object.assign(options, this.geolocationOptions)
          navigator.geolocation.getCurrentPosition(position => {
            let geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
            this.geolocation.loc = geolocation
            this.geolocation.position = position
            if (callback) callback(geolocation, position)
          }, err => {
            this.geocoding = false
            this.$emit('error', 'Cannot get Coordinates from navigator', err)
          }, options)
        }
      },
      // https://github.com/olefirenko/vue-google-autocomplete/blob/master/src/VueGoogleAutocomplete.vue#L237
      updateCoordinates (value) {
        if (!value && !(value.lat || value.lng)) return
        if (!this.geolocation.geocoder) this.geolocation.geocoder = new google.maps.Geocoder()
        this.geolocation.geocoder.geocode({'location': value}, (results, status) => {
          if (status === 'OK') {
            results = this.filterGeocodeResultTypes(results)
            if (results[0]) {
              this.$refs.addressInput.$emit('place_changed', results[0])
              this.autocompleteText = results[0].formatted_address
            } else {
              this.$emit('error', 'no result for provided coordinates')
            }
          } else {
            this.$emit('error', 'error getting address from coords')
          }
        })
      },
      // https://github.com/olefirenko/vue-google-autocomplete/blob/master/src/VueGoogleAutocomplete.vue#L330
      filterGeocodeResultTypes (results) {
        if (!results || !this.types) return results
        let output = []
        let types = [this.types]
        if (types.includes('(cities)')) types = types.concat(CITIES_TYPE)
        if (types.includes('(regions)')) types = types.concat(REGIONS_TYPE)
        for (let r of results) {
          for (let t of r.types) {
            if (types.includes(t)) {
              output.push(r)
              break
            }
          }
        }
        return output
      }
    },

    watch: {
      address () {
        this.$nextTick(() => {
          this.$emit("address-changed", this.address)
        })
      }
    },

    mounted () {
      if (this.value) {
        this.autocompleteText = this.value
      }
    }
  }
</script>
