<template lang='pug'>
  form(:action="searchPath" method="get" ref="form")
    .searchbar.searchbar-rounded
      i.fas.fa-map-marker-alt
      autocomplete-geolocation(@address-changed="$refs.form.submit()" :value="queryParams.formatted_address")

</template>

<script>
  import AutocompleteGeolocation from './AutocompleteGeolocation.vue';

  export default {
    props: {
      searchPath: String
    },
    data () {
      return {
        queryParams: {}
      }
    },
    components: {AutocompleteGeolocation}
  }
</script>
