// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")
require("@rails/actiontext")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";
import 'lightgallery'

// Internal imports, e.g:
// import { initSelect2 } from '../components/init_select2';
import "src/stylesheets/application.sass"
import "@fortawesome/fontawesome-free/css/all.css";
import "src/application/js/vue"
import HeaderFixed from "../src/application/components/helpers/header-fixed"
import MainMenu from "../src/application/components/helpers/main-menu"
import EmojiPicker from "../src/application/components/helpers/emoji-picker"
import LightBox from "../src/application/components/helpers/light-box"
import "src/shared/flash-messages"
import InputSmooth from "../src/application/components/helpers/input-smooth"
import UnfoldingEffect from "../src/application/components/helpers/unfolding-effect"
import '../src/application/js/analytics'
import '../src/application/js/direct_upload'
import MobileNav from "../src/application/js/mobile-nav"

document.addEventListener('turbolinks:load', () => {
  new HeaderFixed()
  new MainMenu()
  new EmojiPicker()
  new LightBox()
  new InputSmooth()
  new UnfoldingEffect()
  new MobileNav()
  $("[data-trigger-submit]").on("change", function (element) {
    $(this).closest('form').submit()
  })
});
