<template lang="pug">
  .text-block 
    #checks.mb-5
      div 
        #items.mb-5 
          .card.shadow-sm.p-4 
            .flex.justify-between
              .title.flex
                .illustration 
                  i.fa.fa-award 
                .name.ml-2 
                  span  Frais de dossier à payer
              .price {{ serviceFees }} €

      br
      h5.mb-3  Je certifie que
      .alert-success
        #service-fees.card.shadow-sm.mb-3 
        .form-check.form-check-inline.p-2 
          div 
            input#terms-notice.form-check-input(type="checkbox" v-model="checked")
          label.form-check-label.pl-2(for="terms-notice") 
            | J'ai lu et j'accepte le
            a(href="https://docs.google.com/document/d/14K_m_IKykfM9_jIyGEbP3L5Ta8eIR_lq/preview" target="_blank")  contrat de location
            | et les
            a(href="http://localhost:3000/basic_pages/mentions-legales-1" target="_blank")  CGV
            | Je renonce expressément à mon droit de rétractation car je souhaite que ma réservation prenne effet et soit traitée immédiatement.

      br
      .form-block.text-right 
        button.btn.btn-primary(v-if='checked' @click="handleSubmit") Payer {{ serviceFees }} €

</template>

<script>
import axios from 'axios'

export default {
  props: {
    room: String,
    sessionId: [String, Number],
    flatmateId: [String, Number],
    roomSlug: String ,
    rentingId: [String, Number],
  },

  data () {
    return {
      stripe: null,
      errors: null,
      checked: false,
      stripeKey: window.paymentConfig.stripeKey,
      serviceFees: null,
      rentAmount: null,
      rentalCosts: null,
      caution: null,
      roomId: null
    }
  },

  methods: {
    handleSubmit() {
      axios.put(`/chambres/${this.roomSlug}/locations/${this.rentingId}`, {
        renting: {
          service_fees: this.serviceFees,
          rent_amount: this.rentAmount,
          caution: this.caution,
          rental_costs: this.rentalCosts,
          flatmate_id: this.flatmateId,
          room_id: this.roomId 
        },
        continue: true
      })
        .then(() => {
          this.stripe.redirectToCheckout({
            sessionId: this.sessionId,
          })
        })
    }
  },

  mounted() {
    const room = JSON.parse(this.room)
    this.serviceFees = room.data.attributes.zone_service_fees
    this.rentAmount = room.data.attributes.rent_amount
    this.rentalCosts = room.data.attributes.rental_costs
    this.caution = room.data.attributes.caution
    this.roomId = room.data.id
    this.stripe = Stripe(this.stripeKey)
  },
}
</script>