export default class HeaderFixed {
  constructor () {
    // change styleguide header display on scroll
    this.$header = $('[data-is-header-scroll]')
    this.scrollLevelMax = 90
    this.checkScrollLevel()
    this.bindEvents()
  }

  // check scroll level : know which class header should have
  checkScrollLevel () {
    if ($(window).scrollTop() > this.scrollLevelMax) {
      return this.$header.addClass('header--fixed')
    } else {
      return this.$header.removeClass('header--fixed')
    }
  }

  // call checkScrollLevel() on scroll
  bindEvents () {
    const self = this
    return $(window).scroll(() => self.checkScrollLevel())
  }
}
