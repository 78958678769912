export default class Lightbox {
  constructor (options) {
    if (options == null) { options = {} }
    const self = this

    this.$element = $('[data-is-lightbox]')
    this.$trigger = $('[data-trigger-lightbox]')
    this.$firstSlide = $('[data-is-first-lightbox]')
    this.selectorList = '.item'

    const defaultOptions = {
      thumbnail: true,
      animateThumb: true,
      showThumbByDefault: true,
      download: false,
      selector: self.selectorList
    }

    this.options = $.extend(true, {}, defaultOptions, options)

    this.init()
  }

  init () {
    const self = this
    this.$element.lightGallery(self.options)
    this.bindEvents()
  }

  bindEvents () {
    const self = this
    if (this.$trigger != null) {
      this.$trigger.on('click', function () {
        self.$firstSlide.trigger('click')
        self.$element.data('lightGallery').slide(0)
      })
    }
  }
};
