<template lang='pug'>
  .form-icon-wrapper
    datepicker(ref="datepicker" :disabled-date="notBeforeOneMonthFromNow" :range="range" format="DD-MM-YYYY" :placeholder="placeholder" v-model="dates" lang="fr" valueType="format" input-class="form-control" :monday-first="true")
</template>

<script>
  import Datepicker from 'vue2-datepicker'
  import fr from 'vue2-datepicker/locale/fr'

  export default {
    components: {
      Datepicker
    },
    props: {
      range: {
        type: Boolean,
        default: false
      },
      name: String,
      initialDate: String,
      disableInOneMonth: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: 'Définir une période'
      }
    },
    data () {
      return {
        dates: this.initialDate,
        lang: fr
      }
    },
    mounted () {
      this.$refs.datepicker.$refs.input.name = this.name
    },
    methods: {
      notBeforeOneMonthFromNow(date) {
        if (this.disableInOneMonth) {
          const current_date = new Date()
          return date < current_date.setMonth(current_date.getMonth() + 1);
        }
      }
    }
  }
</script>

<style>
  .mx-datepicker-range {
    width: 21rem;
  }

  @media (max-width: 960 - 1px) {
    .mx-datepicker-range {
      width: 14rem;
    }
  }
</style>
