<template lang="pug">
  .row.min-h-screen.col-12.p-0.m-0
    .col-lg-6.col-12.p-4(class='md:p-12')
      transition(name="fade" class="row col-12 m-auto")
      h3.text-primary {{ availableRooms }} Chambres disponibles
      br
      h2  {{ formattedAddress }}
      .row.px-4
        .form-group.px-2
          v-select(
            :value="double.value"
            :options="[{label: 'Pour moi', value: false}, {label: 'Pour deux', value: true}]"
            v-model='double' 
            placeholder='Pour moi'
            @input="fetchResults"
          )
        .form-group.px-2
          .dropdown.dropleft
            button#dropdownMenuButton.btn.btn-default.px-8(aria-expanded="false" aria-haspopup="true" data-toggle="dropdown" type="button")
              | Loyer
            .dropdown-menu.dropdown-menu--lg.card.card-hidden.p-8(aria-labelledby="dropdownMenuButton" @click.stop='')
              label.text-xl Votre budget pour le loyer se situe entre :
              vue-slider(type='text' v-model='roomPrice' :enable-cross="false" :min='100' :max='1000')
              br
              .row.justify-around.items-center
                input.form-control.col-5(type='text' v-model.lazy='roomPrice[0]')
                span -
                input.form-control.col-5(type='text' v-model.lazy='roomPrice[1]')
              br
              .btn.btn-primary.flex.justify-center(@click='fetchResults') Filtrer

        .form-group.px-2
          .dropdown
            button#dropdownMenuButton.btn.btn-default.px-8(aria-expanded="false" aria-haspopup="true" data-toggle="dropdown" type="button")
              | LOCATAIRES
            .dropdown-menu.dropdown-menu--lg.card.card-hidden.p-8(aria-labelledby="dropdownMenuButton" @click.stop='')
              .form-group--smooth.form-group--smooth--xl.required(autofocus="autofocus")
                .form-control.input--smooth.input--filled.flex-column.flex
                  .row.ml-4.my-2(v-for="(value, name) in JSON.parse(flatmateTypes)")
                    input(type="checkbox" :id="value" :value="value" v-model="flatmateType")
                    .ml-4 {{ name }}
                label.form-label.label--smooth locataires
              .btn.btn-primary.flex.justify-center(@click='fetchResults') Filtrer

        .form-group.px-2
          .dropdown.dropleft
            button#dropdownMenuButton.btn.btn-default.px-8(aria-expanded="false" aria-haspopup="true" data-toggle="dropdown" type="button")
              | Tranche d'age
            .dropdown-menu.dropdown-menu--lg.card.card-hidden.p-8(aria-labelledby="dropdownMenuButton" @click.stop='')
              .form-group--smooth.form-group--smooth--xl.required(autofocus="autofocus")
                .form-control.input--smooth.input--filled.flex-column.flex
                  .row
                    template(v-for="(value, name) in JSON.parse(flatmateAgeRanges)")
                      .col-6
                        .row.ml-4.my-2
                          input(type="checkbox" :id="value" :value="value" v-model="flatmateAgeRange")
                          .ml-4 {{ name }}
                label.form-label.label--smooth Tranche d'age
              .btn.btn-primary.flex.justify-center(@click='fetchResults') Filtrer

        .form-group.px-2
          .dropdown
            button#dropdownMenuButton.btn.btn-default.px-8(aria-expanded="false" aria-haspopup="true" data-toggle="dropdown" type="button")
              | Equipements
            .dropdown-menu.dropdown-menu--lg.card.card-hidden.p-8(aria-labelledby="dropdownMenuButton" @click.stop='')
              .form-group--smooth.form-group--smooth--xl.required(autofocus="autofocus")
                .form-control.input--smooth.input--filled.flex-column.flex.input--smooth--xxl
                  .row
                    
                    template(v-for="(feature, index) in JSON.parse(flatFeatures)")
                      .px-0.py-2(:class="feature.id == 89 ? 'col-12' : 'col-6'")
                        .row.ml-4.my-2
                          input(type="checkbox" :id="feature.id" :value="feature.id" v-model="flatFeature")
                          .ml-4 {{ feature.name }}
                label.form-label.label--smooth Logement
              .btn.btn-primary.flex.justify-center(@click='fetchResults') Filtrer

      slot

      p(v-show="results.length === 0 && !loading")
        span Nous n'avons pas de chambre dans votre secteur
        span(v-show="otherResults.length")|, voici les plus proches correspondant à votre recherche :


      .spinner-wrapper(v-show="loading")
        .fa.fa-2x.fa-spinner.fa-spin

      transition-group(name="fade" class="row col-12 m-auto p-0")
        .col-lg-6.col-sm-6.col-12.py-6(:key="result.id" v-for="result in results" v-show="!loading")
          result-card(
            :result='result'
            :active="isActiveMarker(result.id) && activeCards.includes(result.id)"
            @hover="activeMarkers = [result.id]; activeCards = [result.id]"
            @unhover="activeMarkers = []; activeCards = []"
            :availability='result.attributes.next_availibility'
          )

      pagination(v-show="results.length" @pageChanged="fetchResults" :totalPages="totalPages")

    .col-md-6.d-lg-block.d-none
      gmap-map(
        :center="{lat: Number(coordinates.latitude), lng: Number(coordinates.longitude)}"
        :zoom="zoom"
        :options="optionsMap"
        ref="googleMap"
      )
        gmap-cluster(:zoomOnClick="true" :maxZoom="18" :styles="this.$root.clusterStyles")
          gmap-marker(
            v-for="(marker, index) in roomMarkers"
            :key="marker.room.id"
            @click="toggleInfoWindow(marker, index)"
            :options="{optimized: false}"
            :icon="isActiveMarker(marker.room.id) ? { url: require('images/map/map-marker-active.png')} : { url: require('images/map/map-marker-flat.png')} "
            :position='marker.position'
          )
          gmap-info-window(
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen=false; activeMarkers = []; activeCards = []"
          )
            div(v-html="infoContent")

</template>

<script>
  /* eslint no-undef: 0 */
  import VueSlider from 'vue-slider-component'
  import ResultCard from './ResultCard'
  import Pagination from "./Pagination"
  import axios from 'axios'
  import { merge } from 'lodash'

  export default {
    props: {
      resourceUrl: String,
      formattedAddress: String,
      coordinates: Object,
      initialDouble: {
        type: Object,
        default: function () {
          return {label: 'Pour moi', value: false}
        } 
      },
      initialSize: Array,
      initialPrice: {
        type: String,
        default: '300, 800'
      },
      initialFlatmateType: {
        type: String,
        default: function () {
          return "[]"
        }
      },
      initialFlatmateAgeRange: {
        type: String,
        default: function () {
          return "[]"
        }
      },
      initialFlatFeatures: {
        type: String,
        default: function () {
          return "[]"
        }
      },
      flatmateTypes: String,
      flatFeatures: String,
      flatmateAgeRanges: String
    },
    components: { 
      Pagination, 
      ResultCard,
      VueSlider
    },
    data () {
      return {
        zoom: 12,
        activeCards: [],
        activeMarkers: [],
        loading: false,
        totalPages: undefined,
        totalEntries: undefined,
        availableRooms: null,
        results: [],
        otherResults: [],
        optionsMap :merge(this.$root.configMap, {}),
        double: this.initialDouble,
        roomPrice: this.initialPrice.split(',').map(x => +x),
        flatmateType: JSON.parse(this.initialFlatmateType),
        flatmateAgeRange: JSON.parse(this.initialFlatmateAgeRange),
        flatFeature: JSON.parse(this.initialFlatFeatures),
        infoWindowPos: {
          lat: 0,
          lng: 0
        },
        infoContent: '',
        infoWinOpen: false,
        infoOptions: {
          pixelOffset: {
            width: 0,
            height: -35
          }
        },
        chartResults: null,
        chartOptions: null,
        showPrice: false,
        showSize: false
      }
    },
    methods: {
      isActiveMarker (resultId) {
        return this.activeMarkers.includes(resultId)
      },

      fetchResults (page = 1) {
        const element = event.target
        if (element.tagName) {
          event.target.parentNode.classList.remove("show")
        }
        this.loading = true
        this.results = []
        axios.get(this.resourceUrl, { params: {
          location: this.formattedAddress,
          latitude: this.coordinates.latitude,
          longitude: this.coordinates.longitude,
          by_published: "true",
          per_page: 10,
          page: page,
          by_double: this.double.value,
          by_price: this.roomPrice.toString(),
          by_flatmate_type: this.flatmateType,
          by_flatmate_age_range: this.flatmateAgeRange,
          by_feature: this.flatFeature
        }}).then(response => {
          if (response.data.data.data.length) {
            this.totalPages = response.data.data.total_pages
            this.totalEntries = response.data.data.total_entries
            this.results = response.data.data.data
            this.availableRooms = response.data.available_rooms
          } else {
            this.fetchOtherResults()
          }
          this.loading = false
        })
      },

      toggleInfoWindow (marker, idx) {
        // marker.position.lat += 0.0003
        this.infoWindowPos = marker.position;
        this.infoContent = this.getInfoWindowContent(marker);
        this.activeMarkers = [marker.room.id]
        this.activeCards = [marker.room.id]

        //check if its the same marker that was selected if yes toggle
        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen;
        }
        //if different marker set infowindow to open and reset current marker index
        else {
          this.infoWinOpen = true;
          this.currentMidx = idx;
        }
      },
      getInfoWindowContent: function (marker) {
        return (`<div class="result-card">
                  <div class="result-card-image">
                    <a href="${marker.room.attributes.url}">
                      <img src="${marker.room.attributes.photos.length > 0 ? marker.room.attributes.photos[0].photo : ''}" alt="Placeholder image" class="room-photo">
                    </a>
                  </div>
                  <br>
                  <a href="${marker.room.attributes.url}">
                    <div class="result-card-content">
                      <div class="name">
                        <div class="name-content">
                          <h2 class="text-3xl text-gray">${marker.room.attributes.flat_name} - ${marker.room.attributes.name}</p>
                        </div>
                      </div>
                      <div class="availability">
                        <div class="availability-content">
                          <p class="title is-4 text-primary">${marker.room.attributes.next_availibility}</p>
                        </div>
                      </div>
                      <div class="price">
                        <div class="price-content">
                          <h3 class="text-secondary is-4">${marker.room.attributes.rent_amount} € /  par mois</p>
                        </div>
                      </div>
                      <div class="size">
                        <div class="size-content">
                          <p class="title is-4">${marker.room.attributes.size} m2</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>`);
      },

      fetchOtherResults () {
        this.loading = true
        this.otherResults = []
        axios.get(this.resourceUrl, { params: {
          location: this.formattedAddress,
          latitude: this.coordinates.latitude,
          longitude: this.coordinates.longitude,
          distance_in_km: 100,
          by_published: "true",
          per_page: 9,
          page: 1,
          by_double: this.double,
          by_price: this.roomPrice.toString(),
          by_flatmate_type: this.flatmateType,
          by_flatmate_age_range: this.flatmateAgeRange,
          by_feature: this.flatFeature
        }}).then(response => {
          this.otherResults = response.data.data.data
          this.totalEntries = response.data.data.total_entries
          this.totalPages = response.data.data.total_pages
          this.loading = false
        })
      },

      setSelectedResult (result) {
        this.currentResult = result

        if (this.zoom !== 17) {
          this.zoom = 17
          this.coordinates = result.position
        }
      }
    },
    watch: {
      roomMarkers () {
        if (this.roomMarkers.length) {
          this.$refs.googleMap.$mapPromise.then(() => {
            const google = window.google
            let bounds = new google.maps.LatLngBounds()
            this.roomMarkers.forEach((marker) => {
              let position = new google.maps.LatLng(marker.position.lat, marker.position.lng)
              bounds.extend(position)
            })
            this.$refs.googleMap.fitBounds(bounds)
          })
        }
      },
    },
    computed: {
      roomMarkers () {
        return this.results.map((room) => {
          let address = room.attributes.address

          if (address) {
            return {
              room,
              position: {
                lat: address.attributes.latitude,
                lng: address.attributes.longitude
              }
            }
          }
        })
      }
    },

    mounted () {
      if (this.coordinates.latitude && this.coordinates.longitude) {
        this.fetchResults()
      }
    }
  }
</script>

<style>
.spinner-wrapper {
  width: 100%;
  text-align: center;
}

.vue-map {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}

.result-card {
  width: 100% !important;
  height: 100% !important;
}

.result-card-image {
  width: 100% !important;
  height: 100% !important;
}

.room-photo {
  width: 100% !important;
  margin: auto;
}

.v-select {
  font-size: 1.4rem;
  font-weight: 500;
  font-family: anisette-std, sans-serif;
  text-transform: uppercase;
}

.card-hidden {
  display: none;
}

.vs__dropdown-toggle {
  border: 1px solid #eee;
  padding: 0.75rem 1.5rem;
  border-radius: 1rem;
  width: 200px;
}

.vs__clear {
  display: none
}
</style>

