<template lang="pug">
.modal.fade.modal--form(:id="modalId" ref="modal" aria-hidden="true" aria-labelledby="name-modalLabel" role="dialog" tabindex="-1")
  .modal-dialog(role="document")
    .modal-content.card

      .modal-header.card-header--secondary
        h2.modal-title
          slot(name="title")

        button.close(aria-label="Close" data-dismiss="modal" type="button")
          span.fa.fa-times(aria-hidden="true" :class="{'text-white': whiteCross}")

      .modal-body
        slot(name="body")

      .modal-footer
        slot(name="footer")

</template>

<script>

export default {
  props: {
    whiteCross: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    modalId () {
      return `modal-${this._uid}`
    }
  },

  methods: {
    show () { $(this.$refs.modal).modal('show') },
    hide () { $(this.$refs.modal).modal('hide') }
  },

  mounted () {
    $(this.$refs.modal).on('show.bs.modal', () => this.$emit('show'))
    $(this.$refs.modal).on('shown.bs.modal', () => this.$emit('shown'))
    $(this.$refs.modal).on('hide.bs.modal', () => this.$emit('hide'))
    $(this.$refs.modal).on('hidden.bs.modal', () => this.$emit('hidden'))
  }
}
</script>
