<template lang="pug">
  .text-block
    form-errors(:errors="errors")
    .form-group.row.required
      .form-label.required
        label Salaire Net *
      .relative.form-controls.p-0.form-control.w-full
        input.required.form-control(type="number" v-model="salary" @change='checkSalary' :class="{'field_with_errors' : errors.some(e => Object.keys(e).includes('salary'))}")
        i.absolute.euro-sign €

    .form-group.row.required
      .form-label
        label Type de contrat de travail *
      select.form-controls.p-0.form-control.required(v-model="contractType" @change='checkContractType' :class="{'field_with_errors' : errors.some(e => Object.keys(e).includes('contract_type'))}")
        option(v-for="contract_type in mutableFlatmate.attributes.contract_types" :value="contract_type[0]") {{ contract_type[0] }}

    .form-group.row.required
      .form-label.col-3
        label Carte d'identité *
      input.form-controls.p-0.col-9(type="file" accept="application/pdf" @change="onFilePicked($event, 'idCardFile')")
    .form-group.row(v-if='initialCardIdFile')
      label.form-label.col-md-3.font-bold Fichier actuel :
      .col-md-6.p-0
        a(:href="initialCardIdFile.path" target='_blank') {{ initialCardIdFile.name }}

    hr
    br
    .form-group.row.required
      .form-label.col-3
        label Fiche de salaire ou dernier bilan *
      input.form-controls.p-0.col-9(type="file" accept="application/pdf" @change="onFilePicked($event, 'salaryFile')")
    .form-group.row(v-if='initialSalaryFile')
      label.form-label.col-md-3.font-bold Fichier actuel :
      .col-md-6.p-0
        a(:href="initialSalaryFile.path" target='_blank') {{ initialSalaryFile.name }}
    hr
    br
    .form-group.row.required
      .form-label.col-3
        label Attestation d'activité *
      input.form-controls.p-0.col-9(type="file" accept="application/pdf" @change="onFilePicked($event, 'activityFile')")
    .form-group.row(v-if='initialActivityFile')
      label.form-label.col-md-3.font-bold Fichier actuel :
      .col-md-6.p-0
        a(:href="initialActivityFile.path" target='_blank') {{ initialActivityFile.name }}
    div(v-if='mutableFlatmate.attributes && mutableFlatmate.attributes.warrantor')
      hr
      br
      div
        .form-group.row.required
          .form-label.col-3
            label Attestation de garant *
          input.form-controls.p-0.col-9(type="file" accept="application/pdf" @change="onFilePicked($event, 'residenceCard')")
        .form-group.row(v-if='initialGuarantorCertificate')
          label.form-label.col-md-3.font-bold Fichier actuel :
          .col-md-6.p-0
            a(:href="initialGuarantorCertificate.path" target='_blank') {{ initialGuarantorCertificate.name }}
    hr
    br
    .form-group.row.required
      .form-label.col-3
        label Avis d'imposition *
      input.form-controls.p-0.col-9(type="file" accept="application/pdf" @change="onFilePicked($event, 'taxNoticeFile')")
    .form-group.row(v-if='initialTaxNoticeFile')
      label.form-label.col-md-3.font-bold Fichier actuel :
      .col-md-6.p-0
        a(:href="initialTaxNoticeFile.path" target='_blank') {{ initialTaxNoticeFile.name }}
    hr
    br
    .form-group.row.required
      .form-label.col-3
        label 3 dernières quittances de loyer *
      input.form-controls.p-0.col-9(type="file" accept="application/pdf" @change="onFilesPicked($event)" multiple)
    .form-group.row(v-if='initialRentReceiptFiles.length > 0')
      label.form-label.col-md-3.font-bold Fichier actuel :
      .col-md-6.p-0
        .link(v-for='rentReceiptFile in initialRentReceiptFiles')
          a(:href="rentReceiptFile.path" target='_blank') {{ rentReceiptFile.name }}
    hr
    br
    div(v-if='mutableFlatmate.attributes && isNotFrench')
      .form-group.row.required
        .form-label.col-3
          label Carte de séjour *
        input.form-controls.p-0.col-9(type="file" accept="application/pdf" @change="onFilePicked($event, 'residenceCard')")
      .form-group.row(v-if='initialResidenceCard')
        label.form-label.col-md-3.font-bold Fichier actuel :
        .col-md-6.p-0
          a(:href="initialResidenceCard.path" target='_blank') {{ initialResidenceCard.name }}

    br
    .form-block.text-right
      button.btn.btn-primary(@click="handleSubmit" :disabled="errors.length !== 0") Envoyer ma demande
    .form-block.text-right.flex.justify-end(v-if='errors.length > 0 && !mutableFlatmate.attributes.warrantor')
      button.btn.btn-secondary 
        a.text-white(:href="urlBack") J'ai un garant
    //-   button.btn.btn-primary(href='https://www.smart-garant.com/') J'ai besoin d'un garant
    //- .form-block.text-right(v-else)
    //-   button.btn.btn-primary(href='https://www.smart-garant.com/') Faire appel à Smart Garant

</template>

<script>
import axios from 'axios'

export default {
  components: {
    FormErrors: () => import('../../shared/components/FormErrors')
  },

  props: {
    room: String,
    flatmateId: [String, Number],
    roomSlug: String,
    flatmate: String
  },

  data () {
    return {
      errors: [],
      rentAmount: null,
      salary: null,
      contractType: null,
      mutableFlatmate: null,
      initialCardIdFile: null,
      initialContractType: null,
      initialSalaryFile: null,
      initialActivityFile: null,
      initialTaxNoticeFile: null,
      initialResidenceCard: null,
      initialGuarantorCertificate: null,
      initialRentReceiptFiles: [],
      idCardFile: {
        path: null,
        name: null
      },
      salaryFile: {
        path: null,
        name: null
      },
      activityFile: {
        path: null,
        name: null
      },
      taxNoticeFile: {
        path: null,
        name: null
      },
      residenceCard: {
        path: null,
        name: null
      },
      guarantorCertificate: {
        path: null,
        name: null
      },
      rentReceiptFiles: [],
      warrantor: null,
      urlBack: null
    }
  },

  computed: {
    isNotFrench() {
      const nationality = this.mutableFlatmate.attributes.nationality.toLowerCase().trim()
      return !nationality.match(/^["fr"].*["se"]$/)
    }
  },
  methods: {
    checkSalary() {
      const errors = this.errors.filter(error => !Object.keys(error).includes('salary')) 
      this.errors = errors; 
      const salaryCheck = this.mutableFlatmate.attributes.warrantor ? this.salary < this.rentAmount * 4 : this.salary < this.rentAmount * 3
      const salaryError = this.mutableFlatmate.attributes.warrantor ? 'En tant que garant votre salaire doit être au moins 4 fois supérieur au loyer, sinon vous pouvez faire appel à Smart Garant.' : 'Votre salaire doit être au moins 3 fois supérieur au loyer, sinon il vous faut un garant.'
      if (salaryCheck) {
        this.errors.push({salary: salaryError});
      }
    },
    checkContractType() {
      const errors = this.errors.filter(error => !Object.keys(error).includes('contract_type'))
      this.errors = errors; 
      if (this.contractType !== "CDI") {
        return this.errors.push({contract_type: 'Un CDI est nécessaire pour pouvoir assurer un loyer, sinon il vous faut un garant.'});
      }
    },
    handleSubmit() {
      const errors = this.errors.filter(error => !Object.keys(error).includes('file'))
      this.errors = errors; 
      if ((!this.idCardFile.path && !this.initialCardIdFile?.name) || (!this.salaryFile.path && !this.initialSalaryFile?.name) || (!this.activityFile.path && !this.initialActivityFile?.name) || (this.rentReceiptFiles.length < 3 && this.initialRentReceiptFiles.length < 3) || this.errors.length > 0) {
        return this.errors.push({file: "Des éléments de votre dossier manquent à l'appel."});
      }
      const formData = new FormData()
      const params = {
        'salary': this.salary,
        'contract_type': this.contractType,
        ...(this.idCardFile.path && { 'card_id_file': this.idCardFile.path }),
        ...(this.salaryFile.path && { 'salary_file': this.salaryFile.path }),
        ...(this.activityFile.path && { 'activity_file': this.activityFile.path }),
        ...(this.taxNoticeFile.path && { 'tax_notice_file': this.taxNoticeFile.path }),
        ...(this.residenceCard.path && { 'residence_card': this.residenceCard.path }),
        ...(this.guarantorCertificate.path && { 'guarantor_certificate': this.guarantorCertificate.path }),
      }

      Object.keys(params).forEach(key => {
        formData.append(`flatmate[${key}]`, params[key]);
      });

      this.rentReceiptFiles.map(file => {
        formData.append(`flatmate[rent_receipt_files][]`, file, file.name);
      })


      axios.put("/compte-locataire", formData, {
        headers: { "Content-Type": "multipart/form-data" }
      }).then(() => {
        window.location.replace(`${window.location.origin}/chambres/${this.roomSlug}/locations/success`);
      })
    },

    onFilePicked (event, file_name) {
      const errors = this.errors.filter(error => Object.keys(error)[0] !== 'file')
      this.errors = errors; 
      const files = event.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this[file_name].name = files[0].name
      })
      this[file_name].path = files[0]
    },

    onFilesPicked(event) {
      const errors = this.errors.filter(error => Object.keys(error)[0] !== 'file')
      this.errors = errors; 
      const files = event.target.files
      Array.from(files).forEach(file => {
        this.rentReceiptFiles.push(file)
      })
    }
  },

  mounted() {
    const flatmate = JSON.parse(this.flatmate)
    const room = JSON.parse(this.room)
    this.mutableFlatmate = flatmate.data
    this.rentAmount = room.data.attributes.rent_amount
    this.salary = this.mutableFlatmate.attributes.salary
    this.contractType = this.mutableFlatmate.attributes.contract_type
    this.warrantor = this.mutableFlatmate.attributes.warrantor
    this.initialCardIdFile = this.mutableFlatmate.attributes.card_id_file
    this.initialSalaryFile = this.mutableFlatmate.attributes.salary_file
    this.initialActivityFile = this.mutableFlatmate.attributes.activity_file
    this.initialTaxNoticeFile = this.mutableFlatmate.attributes.tax_notice_file
    this.initialResidenceCard = this.mutableFlatmate.attributes.residence_card
    this.initialGuarantorCertificate = this.mutableFlatmate.attributes.guarantor_certificate
    if (this.mutableFlatmate.attributes.rent_receipt_files) {
      this.initialRentReceiptFiles = this.mutableFlatmate.attributes.rent_receipt_files
    }
    this.urlBack = `${window.location.origin}/chambres/${this.roomSlug}/locations/identite?warrantor=true`
  }
}
</script>

<style scoped>
  .euro-sign {
    transform: translateY(-50%);
    top: 50%;
    pointer-events: none;
    width: 25px;
    text-align: center;
    right: 5px;
    font-style: normal;
  }

  .field_with_errors {
    border: 2px solid red;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
      -moz-appearance:textfield; /* Firefox */
  }
</style>