<template lang="pug">
  nav
    ul.pagination
      .pagination(v-if="totalPages > 1")
        li.page-item(:class="{ 'disabled': (currentPage <=1) }")
          a.page-link(@click="changePage(currentPage - 1)")
        li.page-item(v-for="page in totalPages" :class="{'active':(page === currentPage)}")
          a.page-link(href="#" @click.prevent="changePage(page)") {{ page }}
        li.page-item(:class="{ 'disabled': (currentPage >= totalPages) }")
          a.page-link.last(rel= 'next' @click.prevent="changePage(currentPage + 1)")
</template>

<script>

export default {
  props: {
    totalPages: Number
  },
  data () {
    return {
      currentPage: 1
    }
  },
  methods: {
    changePage (page) {
      this.$emit('pageChanged', page)
      this.currentPage = page
      this.$scrollTo("body")
    }
  }
}
</script>
