<template lang="pug">
  .form-control.input--smooth.input--filled(:class="{ 'border-red-500' : red }")
    input(type='hidden' name="by_price" :value='roomPrice')
    vue-slider(type='text' v-model='roomPrice' :enable-cross="false" :min='100' :max='1000')
    br
    .row.justify-around.items-center
      input.form-control.col-5(type='text' v-model.lazy='roomPrice[0]')
      span -
      input.form-control.col-5(type='text' v-model.lazy='roomPrice[1]')
</template>

<script>
  /* eslint no-undef: 0 */
  import VueSlider from 'vue-slider-component'

  export default {
    components: { 
      VueSlider
    },
    props: {
      red: Boolean
    },
    data () {
      return {
        roomPrice: [300, 800],
      }
    }
  }
</script>

<style>
  .vue-slider-dot-handle {
    background-color: red !important
  }

  .vue-slider-process {
    background-color: #067790 !important
  }
</style>
