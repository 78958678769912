import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import axios from 'axios'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import MapStyle from 'src/shared/resources/map-style.json'
import vSelect from "vue-select";
import StripeCheckout from '../components/StripeCheckout'
import StripeSepa from '../components/StripeSepa'
import AddressAutocomplete from '../../shared/components/AddressAutocomplete';
import CustomDatePicker from '../../shared/components/CustomDatePicker.vue'
import FlatSearchBar from '../components/FlatSearchBar'
import SearchResult from '../components/SearchResult'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import FindModel from '../../shared/components/FindModel.vue'
import CreateUserModal from '../components/CreateUserModal.vue'
import Modal from '../../shared/components/Modal.vue'
import FormErrorsWrapper from '../../shared/components/FormErrorsWrapper.vue'
import ChartFilter from '../components/ChartFilter.vue'
import VueSlider from 'vue-slider-component'
import PriceFilter from '../components/PriceFilter.vue'
import FlatmateStatus from '../components/FlatmateStatus.vue'
import NewRentingModal from '../components/NewRentingModal.vue'


Vue.component("v-select", vSelect);
Vue.use(TurbolinksAdapter)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAYDk3aePAyqLH8L14dRnj8dkiIWgWuytU',
    libraries: 'places,drawing' // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    // If you want to set the version, you can do so:
    // v: '3.26',
  },

  // If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  // autobindAllEvents: false,

  // If you want to manually install components, e.g.
  // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then disable the following:
  installComponents: true
})

Vue.component('GmapCluster', GmapCluster)
Vue.use(VueAwesomeSwiper)

document.addEventListener('turbolinks:load', () => {
  let token = document.head.querySelector('meta[name="csrf-token"]').content
  axios.defaults.headers.common['X-CSRF-Token'] = token
  axios.defaults.headers.common['Accept'] = 'application/json'
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  window.paymentConfig = {
    stripeKey: document.querySelector("meta[name='stripe-public-key']").content
  }
  
  let app = document.querySelector('#vue_app')
  if (app != null) {
    window.vm = new Vue({
      el: '#vue_app',
      components: { 
        StripeCheckout,
        AddressAutocomplete,
        CustomDatePicker,
        FlatSearchBar,
        SearchResult,
        FindModel,
        CreateUserModal,
        StripeSepa,
        Modal,
        FormErrorsWrapper,
        ChartFilter,
        VueSlider,
        PriceFilter,
        FlatmateStatus,
        NewRentingModal
      },
      data () {
        return {
          configMap: {
            styles: MapStyle,
            maxZoom: 19,
            disableDefaultUI: true,
            zoomControl: true
          },

          clusterStyles: [
            {
              textColor: 'black',
              url: require('images/map/map-cluster.png'),
              height: 50,
              width: 50
            }
          ]
        }
      }
    })
  }
})