<template lang="pug">
  .form-icon-wrapper(v-if='initialList !== null')
    form(@submit.prevent.stop='')
      v-select(
        :options='initialList.data' 
        :getOptionLabel='opt => opt.attributes[`${looking_for}`]' 
        v-model='mutableModel' 
        @input='handleModel'
        :placeholder='placeHolder'
        :disabled='disabled'
      )
        span(slot="no-options") Oups ! Il n'y a rien par ici.
    input(type='hidden' v-if="name !== 'undefined'" :name='name' :value='mutableModel ? mutableModel.id : null' :id="name")
</template>

<script>
import axios from "axios";
import EventBus from "../../admin/components/event-bus";

export default {
  props: {
    initialModelId: {
      type: Number,
      default: null,
    },
    initialList: {
      type: [Array, Object],
      default: null,
    },
    name: String,
    modelName: String,
    looking_for: {
      type: String,
      default: "name",
    },
    placeHolder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: "",
      mutableModel: null,
    };
  },
  methods: {
    handleModel(model) {
      if (model) {
        this.$emit("change", model);
        this.mutableModel = model;
      } else {
        this.mutableModel = null;
        this.query = "";
      }
    },
  },
  created() {
    if (this.initialModelId) {
      axios
        .get(`/admin/${this.modelName}.json`, {
          params: { by_id: this.initialModelId },
        })
        .then(({data}) => {
          this.handleModel(data.data[0]);
        });
    }
  },
  mounted() {
    EventBus.$on("loadModel", (payLoad) => {
      this.mutableModel = payLoad.data;
    });
  },
};
</script>

<style scoped>
.form-icon-wrapper {
  padding: 0;
}
</style>
