<template lang='pug'>
  div
    .form-icon-wrapper
      form(@submit.prevent.stop="")
        gmap-autocomplete(
          ref="addressInput"
          :id="id"
          class="form-control"
          :placeholder="placeholder"
          @place_changed="getAddressData"
          @keypress.enter.prevent.stop=""
          :types="types"
          :disabled="disabled"
          :componentRestrictions="{ country: ['fr', 'be', 'it', 'es', 'lu', 'ch', 'ca', 'us'] }"
          :value="address.attributes.display_name"
        )

      a.shb-close(v-if="!disabled" href="#", @click.prevent="clear")

    .invisible(v-if="address && name")
      input(type="hidden" :value="address.attributes.longitude" :name="`${name}[longitude]`")
      input(type="hidden" :value="address.attributes.latitude" :name="`${name}[latitude]`")
      input(type="hidden" :value="address.attributes.city" :name="`${name}[city]`")
      input(type="hidden" :value="address.attributes.postal_code" :name="`${name}[postal_code]`")
      input(type="hidden" :value="address.attributes.street_number" :name="`${name}[street_number]`")
      input(type="hidden" :value="address.attributes.street_name" :name="`${name}[street_name]`")
      input(type="hidden" :value="address.attributes.display_name" :name="`${name}[display_name]`")
      input(type="hidden" :value="address.attributes.light" :name="`${name}[light]`")

      input(type="hidden" :name="`${name}[_destroy]`" class="form-control" :value="destroy")

</template>

<script>
  /// see  https://github.com/olefirenko/vue-google-autocomplete/blob/master/src/VueGoogleAutocomplete.vue#L18
  const ADDRESS_COMPONENTS = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    administrative_area_level_2: 'county',
    country: 'long_name',
    postal_code: 'short_name'
  }

  export default {
    model: {
      prop: "currentAddress",
      event: 'change'
    },

    props: {
      id: {
        type: String,
        default: "autocomplete"
      },

      types: {
        type: Array,
        default () { return ['address'] }
      },

      disabled: {
        type: Boolean,
        default: false
      },

      name: {
        type: String
      },

      placeholder: String,

      currentAddress: {
        type: Object,
        default () {
          return {
            attributes: {
              latitude: undefined,
              longitude: undefined,
              display_name: ""
            }
          }
        }
      }
    },

    data () {
      return {
        address: this.currentAddress
      }
    },

    computed: {
      destroy () {
        return !this.address.attributes.latitude || !this.address.attributes.longitude
      }
    },

    watch: {
      currentAddress () {
        this.address = this.currentAddress
        this.$refs.addressInput.$el.value = this.address.attributes.display_name
      }
    },

    methods: {
      getAddressData (place) {
        /// see https://github.com/olefirenko/vue-google-autocomplete/blob/master/src/VueGoogleAutocomplete.vue#L307
        let addressData = {}
        for (let i = 0; i < place.address_components.length; i++) {
          let addressType = place.address_components[i].types[0]
          if (ADDRESS_COMPONENTS[addressType]) {
            let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]]
            addressData[addressType] = val
          }
        }

        this.address.attributes.longitude = place.geometry.location.lng()
        this.address.attributes.latitude = place.geometry.location.lat()
        this.address.attributes.city = addressData.locality || addressData.administrative_area_level_1 || addressData.administrative_area_level_2
        this.address.attributes.postal_code = addressData.postal_code
        this.address.attributes.street_number = addressData.street_number
        this.address.attributes.street_name = addressData.route
        this.address.attributes.country = addressData.country
        this.address.attributes.display_name = place.formatted_address
        this.address.attributes.light = addressData.country === 'Réunion'

        this.$emit('change', this.address)
      },

      clear () {
        this.$refs.addressInput.$el.value = ""

        this.address = {
          attributes: {
            latitude: undefined,
            longitude: undefined,
            display_name: ""
          }
        }

        this.$emit('change', this.address)
      }
    }
  }
</script>
