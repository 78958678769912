<template lang='pug'>
  modal(ref="modal" :id='modalId' whiteCross=true)
    template(slot="title")
      .flex.items-center
        .tab.text-white(:class="{ active: !login }" @click="login = true") 
          h4(:class="{ 'font-bold': login }") Utilisateur existant
        .mx-8.text-white |
        .tab.text-white(:class="{ active: login }" @click="login = false") 
          h4(:class="{ 'font-bold': !login }") Nouvel utilisateur

    .logo-secondary(slot="body" v-if='login')
      form#new_user.simple_form.form-horizontal(action='/users/sign_in' method='post')
        input(type="hidden" name="authenticity_token" :value="token")
        .form-group--smooth.form-group--smooth--xl.required(autofocus='autofocus')
          .form-group.email.optional.user_email
            label.email.optional(for='user_email') Email
            input#user_email.form-control.required.input--smooth.string.email(type='email' value='' name='user[email]')
        .form-group--smooth.form-group--smooth--xl.required
          .form-group.password.optional.user_password
            label.password.optional(for='user_password') Mot de passe
            input#user_password.form-control.required.input--smooth.string.optional(type='password' name='user[password]')
        .form-submit.flex.justify-center(slot="footer")
          button.btn.btn-primary(type="submit")
            span Se connecter


    form#new_user.simple_form.new_user.logo-primary(slot="body" action="/users" method="post" v-else)
      input(type="hidden" name="authenticity_token" :value="token")
      .form-group--smooth.form-group--smooth--xl.required(autofocus='autofocus')
        .form-group.string.required.user_firstname
          label.string.required(for='user_firstname')
            | Pr&eacute;nom 
            abbr(title='required') *
          input#user_firstname.form-control.required.input--smooth.string.firstname(required='required' v-model="firstname" name='user[firstname]')
      .form-group--smooth.form-group--smooth--xl.required
        .form-group.string.required.user_lastname
          label.string.required(for='user_lastname')
            | Nom 
            abbr(title='required') *
          input#user_lastname.form-control.required.input--smooth.string.lastname(required='required' v-model="lastname" name='user[lastname]')
      .form-group--smooth.form-group--smooth--xl.required
        .form-group.email.required.user_email
          label.email.required(for='user_email')
            | Email 
            abbr(title='required') *
          input#user_email.form-control.required.input--smooth.string.email(required='required' v-model="email" type='email' name='user[email]')
      .form-group--smooth.form-group--smooth--xl.required
        .form-group.password.required.user_password
          label.password.required(for='user_password')
            | Mot de passe 
            abbr(title='required') *
          input#user_password.form-control.required.input--smooth.string.password(required='required' v-model="password" type='password' name='user[password]')
      .text-center
        p
          | MISIMO s&apos;engage &agrave; respecter vos donn&eacute;es. En continuant votre inscription, vous acceptez nos 
          a(href='http://localhost:3000/basic_pages/mentions-legales-2') Mentions l&eacute;gales.
          br
      .form-submit.flex.justify-center(slot="footer")
        button.btn.btn-primary(type="submit")
          span S'inscrire


</template>

<script>
  /* eslint no-undef: 0 */
   export default {
    components: {
      FormErrors: () => import('../../shared/components/FormErrors'),
      Modal: () => import('../../shared/components/Modal')
    },

    props: {
      modalId: [String, Number],
      backButtonLabel: {
        type: String,
        default: 'Annuler'
      }
    },

    data () {
      return {
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        errors: null,
        login: true
      }
    },

    computed: {
      token() {
        return document.head.querySelector('meta[name="csrf-token"]').content
      }
    },

    methods: {
      show () { this.$refs.modal.show() },
      hide () { this.$refs.modal.hide() }
    }
  }
</script>

<style scoped>
  .tab {
    cursor: pointer
  }
</style>