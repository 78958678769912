<template lang="pug">
  div
    .error_explanation(v-if="errors")
      strong Des erreurs ont été rencontrées
      ul
        li(v-for="error in errors") {{ error }}

    slot

</template>

<script>
import FlashMessage from "src/shared/components/flash-messages"
import { get } from "lodash"

export default {
  props: {
    successMessage: {
      type: String
    },
    dangerMessage: {
      type: String
    }
  },
  data () {
    return {
      errors: null
    }
  },
  computed: {
    flash_error () {
      return this.dangerMessage || "Une erreur est survenue"
    },

    flash_success () {
      return this.successMessage || "Votre demande a été traitée"
    }
  },
  mounted () {
    document.body.addEventListener('ajax:error', (event) => {
      this.errors = get(event, 'detail[0].errors')
    })

    document.body.addEventListener('ajax:success', (event) => {
      new FlashMessage(this.flash_success, "success")
    })
  }
}
</script>
