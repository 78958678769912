export default class MainMenu {
  constructor () {
    this.toggleElement = $('[data-trigger-main-menu]')
    this.closeElement = $('[data-trigger-close-menu]')
    this.menu = $('[data-is-main-menu]')
    this.classToggle = 'show'

    this.bindEvents()
  }

  bindEvents () {
    const self = this
    this.toggleElement.on('click', function () {
      $(this).toggleClass(self.classToggle)
      self.menu.toggleClass(self.classToggle)
    })
    this.closeElement.on('click', function () {
      self.toggleElement.removeClass(self.classToggle)
      self.menu.removeClass(self.classToggle)
    })
  }
}
