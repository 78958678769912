<template lang="pug">
  .card(:class="{'active': active, 'overlay-indispo': !available }" @mouseenter='hovered' @mouseleave='unhovered')
    swiper.swiper(:options="swiperOptions" dir="rtl")
      .swiper-slide(:key="resultPhoto" v-for='resultPhoto in resultPhotos')
        img.img-fluid(:src="resultPhoto" @click='linkToRoom' v-if="resultPhoto")
      .swiper-button-prev(slot="button-prev")
      .swiper-button-next(slot="button-next")
    .thumb__content.p-8(@click='linkToRoom')
      h2.text-3xl.text-gray(@click='linkToRoom') {{ flatName + " - " +  name}}
      .availability 
        .text-primary.h4 {{ availability }}
      .price
        h3.text-secondary {{ result.attributes.rent_amount }}€ / mois
</template>

<script>
export default {
  props: {
    result: Object,
    active: Boolean,
    availability: String
  },
  data () {
    return {
      name: this.result.attributes.name,
      url: this.result.attributes.url,
      nextAvailibity: this.result.attributes.next_availibility,
      size: this.result.attributes.size,
      photos: this.result.attributes.photos,
      flatName: this.result.attributes.flat_name,
      flatPhotos: this.result.attributes.flat_photos,
      available: this.result.attributes.available,
      swiperOptions: {
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  methods: {
    hovered () {
      this.$emit('hover')
    },
    unhovered () {
      this.$emit('unhover')
    },
    linkToRoom () {
      if (!this.available) return
      const path = new URL(this.url).pathname
      window.location.pathname = path
    }
  },
  computed: {
    resultPhotos() {
      const initialPhotos = this.photos.concat(this.flatPhotos)
      return initialPhotos.map(object => object.photo.replace('https://', 'https://www.'))
    }
  }
}
</script>

<style scoped>
  .swiper {
    height: 210px;
    width: 100%;
  }
  .overlay-indispo:after {
    position: absolute;
    content: 'Indisponible';
    z-index: 5;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: black
  }
</style>
