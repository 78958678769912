window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}

gtag("js", new Date());

const trackGoogleAnalytics = (event) => {
  gtag("config", "G-STET6CQDTX", {
    page_location: event.data.url,
    page_path: event.srcElement.location.pathname,
    page_title: event.srcElement.title,
  });
  gtag("config", "AW-11118564794", {
    page_location: event.data.url,
    page_path: event.srcElement.location.pathname,
    page_title: event.srcElement.title,
  });

  const contact_submit = document.getElementById("contact_submit");
  if (contact_submit) {
    contact_submit.addEventListener("click", () => {
      gtag("event", "conversion", {
        send_to: "AW-11118564794/8YaZCJrw8ZEYELqr37Up",
      })
    }
    );
  }
};

document.addEventListener("turbolinks:load", trackGoogleAnalytics);
